<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 border dark:border-gray-700 rounded p-5 flex flex-col space-y-5 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3 dark:text-gray-400">
                <div class="flex items-start justify-between">
                    <button 
                        :title="$t('actions.close', $store.state.locale)"
                        @click.prevent="$emit('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <h1 class="text-xl dark:text-gray-200">
                    {{ $t('matchModal.title', $store.state.locale) }}: {{ parent_product.name }}
                </h1>
               
                <div v-if="matching_status === 'fetched'" class="flex flex-col space-y-5  h-96 overflow-y-auto overscroll-contain text-sm">
                    <p v-if="parent_product.formulator">
                        {{ $t('productTable.cols.formulator', $store.state.locale) }}: 
                        <span class="font-semibold">
                            {{ parent_product.formulator.name }} 
                        </span>
                    </p>
                    <p>
                        {{ $t('productTable.cols.quantity', $store.state.locale) }}: 
                        <span class="font-semibold">
                            {{ parent_product.incheck_reference.quantity / 100 }} Kg
                        </span>
                    </p>
                    <p>
                        {{ $t('productTable.cols.reference_date', $store.state.locale) }}: 
                        <span class="font-semibold">
                            {{ setReferenceDate }}
                        </span>
                    </p>
                    <div class="flex flex-col space-y-3">
                        <span>{{ $t('matchModal.associated_product', $store.state.locale) }}:</span>
                        <span v-if="!zdhc_product" class="font-semibold">
                            {{ $t('matchModal.no_associated_product', $store.state.locale) }}
                        </span>
                        <div v-else class="flex flex-col space-y-5 bg-gray-100 dark:bg-gray-800 rounded-md p-2">
                            <!-- Name -->
                            <p>
                                {{ $t('productTable.cols.name', $store.state.locale) }}:
                                <span class="font-semibold capitalize">
                                    {{ setZdhcProductName }}
                                </span>
                            </p>
                            <!-- Code -->
                            <div v-if="zdhc_product.data && zdhc_product.data.code" class="flex flex-col space-y-3">
                                <p>
                                    {{ $t('matchModal.code', $store.state.locale) }}:
                                    <span class="font-semibold">
                                        {{ zdhc_product.data.code }}
                                    </span>
                                </p>
                            </div>
                            <!-- Formulator -->
                            <div v-if="zdhc_product.formulator && zdhc_product.formulator.name " class="flex flex-col space-y-3">
                                <p>
                                    {{ $t('product.create.formulator', $store.state.locale) }}:
                                    <span class="font-semibold">
                                        {{ zdhc_product.formulator.name }}
                                    </span>
                                </p>
                            </div>
                            <!-- Certifications -->
                            <p>
                                {{ $t('matchModal.certifications', $store.state.locale) }}:
                                <span v-if="!zdhc_product.certifications.length" class="font-semibold">
                                   {{ $t('matchModal.no_certifications', $store.state.locale) }}
                                </span>
                            </p>
                            <div v-if="zdhc_product.certifications.length" class="felx flex-col space-y-3">
                                <div
                                    v-for="(c, i) in zdhc_product.certifications" :key="i"
                                    class="felx flex-col space-y-3 bg-gray-200 dark:bg-gray-700 p-2 rounded-md"
                                >
                                    <p>
                                        {{ $t('matchModal.certification_type', $store.state.locale) }}:
                                        <span class="font-semibold">
                                        {{ c.certification }}
                                        </span>
                                    </p>
                                    <p>
                                        {{ $t('matchModal.certification_result', $store.state.locale) }}:
                                        <span class="font-semibold">
                                        {{ c.certification_result }}
                                        </span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div v-if="matching_status === 'loading'" class="flex items-center justify-center h-96 overflow-y-auto overscroll-contain">
                    <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                </div>
                <div class="flex items-center justify-end space-x-3">
                    <button 
                        :title="$t('actions.close', $store.state.locale)"
                        @click.prevent="$emit('closeModal')"
                        class="px-5 py-3 font-medium leading-5 text-center text-blue-500 bg-white dark:bg-gray-800 dark:text-blue-400 rounded-2xl lg:mt-0 hover:bg-gray-100 dark:hover:bg-gray-700 duration-300 lg:w-auto">
                        {{ $t('actions.close', $store.state.locale) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore} from 'vuex';
import moment from 'moment';
//import { useI18n } from 'vue-i18n';
//import SnackbarComposables from '../../composables/SnackbarComposables';
import ZdhcProductService from '../../services/ZdhcProductService';
export default {
    emits:['closeModal', 'newSuggestion'],
    props:
    {
        parent_product:{required:true}
    },
    setup(props)
    {
        /** 
         *
         * Data
         *
         */
        const store = useStore();
        const zdhc_product = ref(null);
        const matching_status = ref('loading');
        moment.locale(store.state.locale)
        //const {t} = useI18n();
        const { showZdhcProduct } = ZdhcProductService();

        /** 
         *
         * Computed
         *
         */
        const setZdhcProductName = computed( () => 
        {
            if(zdhc_product.value)
            {
                try 
                {
                    if(JSON.parse(zdhc_product.value.name))
                    {

                        let name = JSON.parse(zdhc_product.value.name)
                        return name[store.state.locale] ? name[store.state.locale].toLowerCase() 
                        : (name['en'] ? name['en'].toLowerCase() : name[Object.keys(name)[0]].toLowerCase());
                    }
                }
                catch(e)
                {
                    return zdhc_product.value.name.toLowerCase();
                }
            }
            return '';
        });
        const setReferenceDate = computed( () => 
        {
            return props.parent_product.incheck_reference && props.parent_product.incheck_reference.reference_date 
                    ? moment(props.parent_product.incheck_reference.reference_date).format('MMMM YYYY') : '-';
        });

        /** 
         *
         * Methods
         *
         */
        const init =  async () => 
        {
            matching_status.value = 'loading';
            if(props.parent_product && props.parent_product.incheck_reference.suggestion)
            {
                const res = await showZdhcProduct(props.parent_product.incheck_reference.suggestion.zdhc_product_id);
                zdhc_product.value = res.result;
                if(res) matching_status.value = 'fetched';
            }

            if(props.parent_product && props.parent_product.incheck_reference.match)
            {
                const res = await showZdhcProduct(props.parent_product.incheck_reference.match.id);
                zdhc_product.value = res.result;
                if(res) matching_status.value = 'fetched';
            }
        };

        /** 
         *
         * Hooks
         *
         */
        init();

        return {
            matching_status,
            zdhc_product,
            setZdhcProductName,
            setReferenceDate
        }
    }
}
</script>